import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Toolbar, Typography, Link, Button, Container } from '@mui/material';
import CreateUser from './components/CreateUser';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import Login from './components/Login';
import AfterSignUp from './components/AfterSignUp';
import GoogleSignIn from './components/GoogleSignIn';
import MailVerified from './components/MailVerified'
import Home from './components/Home';
import Pricing from './components/Pricing';
import SingUp from './components/SignUp';
import AfterLogin from './components/AfterLogin';
import Profile from './components/Profile';
import Pay from './components/Pay';
import Webhook from './components/Webhook';

function App() {

    const email = localStorage.getItem('email')
    return (<>
        <AppBar position="static">
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '8vh' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Link href="/" underline="none">
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <img style={{ width: '28px', height: '28px', marginRight: '5px' }} src='images/logo.png' />
                            <Typography color={'white'} variant='h5'>
                                Laitter
                            </Typography>
                        </Box>

                    </Link>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', }}>
                        <Link sx={{ color: 'white' }} mr={2} href='/pricing'>
                            Pricing
                        </Link>

                        {email ? (
                            <Link sx={{ color: '#8ffcff' }} href='/profile'>
                                {email}
                            </Link>
                        ) : (<Link sx={{ color: '#8ffcff' }} href='/login'>
                            Login
                        </Link>)
                        }

                    </Box>
                </Box>
            </Container>
        </AppBar>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/afterSingUp" element={<AfterSignUp />} />
                <Route path="/mailVerified" element={<MailVerified />} />
                <Route path="/googleSignIn" element={<GoogleSignIn />} />
                <Route path="/createUser" element={<CreateUser />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/signUp" element={<SingUp />} />
                <Route path="/afterLogin" element={<AfterLogin />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/pay" element={<Pay />} />
                <Route path="/webhook" element={<Webhook />} />
            </Routes>
        </BrowserRouter>
    </>


    );
}

export default App;
