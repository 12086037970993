import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA1EkMladFDtycnh0G1U3BTq4R94wn7wTM",
  authDomain: "mail-b42c2.firebaseapp.com",
  // 其他配置信息
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
