import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import '../css/style.css'
import PayPalButton from './PayPalButton';
function Pay() {
    return (
        <Grid container className='center' style={{display: 'flex',justifyContent:'center',alignItems: 'center',height:'80vh' }}>
        <Grid item xs={10} md={4} lg={3}>
            <Card sx={{ width: '100%', position: 'relative',boxShadow: 3,  }}>
                <CardContent>
                    <Typography variant='h6'>
                        订阅成为PLUS用户:$9.9/month
                    </Typography>
                    <Typography variant='body2' color={'GrayText'} sx={{mb:5}}>
                    之后你可以随时终止订阅
                    </Typography>
                    <PayPalButton/>
                </CardContent></Card>
        </Grid>
        </Grid>
    );
}

export default Pay;