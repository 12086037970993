import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Toolbar, Typography, Link, Button, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link as Linkto } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
function Pricing() {
    const email = localStorage.getItem('email')
    const [user, setUser] = useState(undefined)
    const [isVip, setIsVip] = useState(false)
    useEffect(() => {
        if (email) {
            const userDetails = {
                email: email.toLowerCase(),
            };
            const url = apiUrl + '/getUser'
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userDetails)
            }).then(response => response.json())
                .then(data => {
                    setUser(data)
                    console.log('User details saved:', data)
                })
                .catch(error => {
                    console.error('Error saving user details:', error)
                });
        }
    }, []);
    useEffect(()=>{
        if(user){
            setIsVip(user.isVip)
            console.log(isVip)
        }

    },[user])

    return (
        <Box>
        <Container>
            <Grid container className='center' spacing={5}>
                <Grid item lg={4}>
                    <Card sx={{ height: '500px', backgroundColor: '#f8fffa' }}>
                        <CardContent>

                            <Typography variant='body1'>
                                Basic
                            </Typography>
                            <Typography variant='h3'>
                                Free
                            </Typography>
                            {!user ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    style={{ marginTop: '50px' }}
                                    sx={{ textTransform: 'none' }}
                                    component={Link} href="/createUser"
                                >
                                    Get started
                                </Button>
                            ) : isVip ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled
                                    style={{ marginTop: '50px' }}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Free
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled
                                    style={{ marginTop: '50px' }}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Current
                                </Button>
                            )}
                            <List sx={{ width: '100%' }}>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`personality AI`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`one email every 3 days`} />
                                </ListItem>
                            </List>
                        </CardContent>

                    </Card>
                </Grid>
                <Grid item lg={4}>
                    <Card sx={{ height: '500px', backgroundColor: '#97f0ff' }}>
                        <CardContent>
                            <Typography variant='body1'>
                                Plus
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant='h3'>
                                    $9.9
                                </Typography>
                                <Typography variant='body1' sx={{ mt: 3 }}>/month</Typography>
                            </Box>
                            {!user ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    style={{ marginTop: '50px' }}
                                    sx={{ textTransform: 'none' }}
                                    component={Link} href="/createUser"
                                >
                                    Get started
                                </Button>
                            ) : isVip ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled
                                    style={{ marginTop: '50px' }}
                                    sx={{ textTransform: 'none' }}
                                >
                                    Current
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    style={{ marginTop: '50px' }}
                                    sx={{ textTransform: 'none' }}
                                    component={Link} href="/pay"
                                >
                                    Get started
                                </Button>
                            )}
                            <List sx={{ width: '100%' }}>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`personality AI`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`one email per day`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`more smart AI`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ minWidth: 0 }}>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`It's possible to email the AI`} />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </Box >
    );
}
export default Pricing;