import React, { useState } from 'react';
import { TextField, Button, Container, Box, Grid, Dialog, CircularProgress, Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import GoogleSignIn from './GoogleSignIn';
import { sendSignInLinkToEmail } from "firebase/auth";
import auth from '../configs/firebase-config';
import { BrowserRouter as Router, Routes, Route, Link as Linkto } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/style.css'
const apiUrl = process.env.REACT_APP_API_URL;
const verifiedUrl=process.env.REACT_APP_MAIL_VERIFIED_URL
function Login() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validateEmail = (email) => {
    const value = email.trim();
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    if (value === '') {
      setError('Email cannot be empty.');
      return false
    } else if (value.includes(' ')) {
      setError('Email cannot contain spaces.');
      return false
    }
    if (!emailPattern.test(value)) {
      setError('Please enter a valid email address.');
      return false
    }
    return true
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const emailRight = validateEmail(email)
    if (!emailRight) {
      return
    } else {
      setError('');
      // 在这里处理按钮点击逻辑
      console.log('电子邮件有效:', email);
    }
    try {
      setLoading(true); // 开始加载
      const actionCodeSettings = {
        url: verifiedUrl, // 用户点击链接后将重定向到此 URL
        handleCodeInApp: true,
      };
      const userDetails = {
        email: email.toLowerCase(),
      };
      const url = apiUrl + '/login'
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails)
      }).then(response => response.json())
        .then(data => {
          console.log(data)

          if (data == 'noVerified') {
            setLoading(false);
            setError('This email address has been registered but not yet verified. Please go to your inbox and click the link in the verification email to complete the process.')
            return
          }
          else if (data == 'noExist') {
            setLoading(false);
            setError('This email address has not been registered yet. Please click the link below to create an AI and register your account.')
            return
          }
          sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
              setLoading(false);
              // 邮件发送成功，将 email 存储在 localStorage 以便后续验证
              window.localStorage.setItem('emailForSignIn', email.toLowerCase());
              navigate('/afterLogin');
              console.log('登录链接已发送到:', email);
            })
            .catch((error) => {
              setLoading(false); // 开始加载
              console.error('发送登录链接失败:', error);
            });
          console.log('User details saved:', data)
        })
        .catch(error => {
          setLoading(false);
          console.error('Error saving user details:', error)
        });


    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  // Render form for signup and signin
  return (
    <Grid container className='center'>
      <Grid item xs={10} md={4} lg={2}>
        <Box component="form" onSubmit={handleLogin} noValidate display="flex" flexDirection="column" alignItems="center">

          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            helperText={error}
            error={!!error}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: '10px' }}
            type='submit'
          >
            Login
          </Button>
          <Link mt={2} href='/createUser' underline="hover">
            Haven't you created an AI and registered yet?Please register to get started
          </Link>
          {/* <Divider sx={{ width: '100%', mt: 5, color: 'gray', fontSize: '24px' }}>
            or
          </Divider>
          <GoogleSignIn /> */}
          <Dialog open={loading}>
            <Box p={2} display="flex" alignItems="center">
              <CircularProgress />
              <span style={{ marginLeft: 10 }}>...</span>
            </Box>
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
