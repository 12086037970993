import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Grid, Dialog, CircularProgress, Link, Card, CardContent, Chip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import auth from '../configs/firebase-config';
import { BrowserRouter as Router, Routes, Route, Link as Linkto } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/style.css'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const apiUrl = process.env.REACT_APP_API_URL;

function Profile() {
    const navigate = useNavigate();
    const email = localStorage.getItem('email').toLowerCase();
    const [user, setUser] = useState({})
    const [vipEndTime, setVipEndTime] = useState('')
    const [isVip, setIsVip] = useState(false)
    const [isReceiving, setIsReceiving] = useState(true)
    const [unsubscriptionDialogOpen, setUnsubscriptionDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (email) {
            const userDetails = {
                email: email,
            };
            const url = apiUrl + '/getUser'
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userDetails)
            }).then(response => response.json())
                .then(data => {
                    setUser(data)
                    console.log('User details saved:', data)
                    if (data.renewTime) {
                        const endTime = data.renewTime.split('T')[0]
                        setVipEndTime(endTime)
                    }
                    setIsReceiving(data.isReceiving)
                    setIsVip(data.isVip)

                })
                .catch(error => {
                    console.error('Error saving user details:', error)
                });
        }
    }, []);
    const handleLogout = async (event) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        navigate('/')
        window.location.reload();
    }
    const handleToggleReceiving = async (event) => {
        const url = apiUrl + '/toggleReceiving'
        const token = localStorage.getItem('token')
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, token: token })
        }).then(response => response.json())
            .then(data => {
                setIsReceiving(data.isReceiving)
            })
            .catch(error => {
                console.error('Error saving user details:', error)
            });
    }
    const handleUnsubscription = async (event) => {
        const url = apiUrl + '/cancel-subscription'
        const token = localStorage.getItem('token')
        setLoading(true)
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, token: token })
        }).then(response => response.json())
            .then(data => {
                setLoading(false)
                window.location.reload();
            })
            .catch(error => {
                setLoading(false)
                console.error('Error saving user details:', error)
            });
    }
    const handleUnsubscriptionDialogOpen = async (event) => {
        setUnsubscriptionDialogOpen(true)
    }
    const handleUnsubscriptionDialogClose = async (event) => {
        setUnsubscriptionDialogOpen(false)
    }
    // Render form for signup and signin
    return (
        <Container sx={{ mt: 5 }} maxWidth='md'>
            <Card>
                <CardContent>
                    <Typography variant='h5' color={'gray'} sx={{ mb: 4 }}>
                        Plan
                    </Typography>
                    <Typography color={'gray'} sx={{ mb: 2 }}>Received {user.mailCount} emails from AI.</Typography>
                    {isVip ? (<Box sx={{ mt: 2 }} display="flex" alignItems="center" flexDirection={'row'} >
                        <Chip label="Plus Plan Activated" />
                        <Typography color={'gray'} sx={{ ml: 1 }}>Auto-renewal time:{user.renewTime}</Typography>
                        <Button onClick={handleUnsubscriptionDialogOpen} sx={{ textTransform: 'none' }} >Unsubscription</Button>
                    </Box>) : (
                        <Box><Chip label="Basic Plan Activated" /><Button component={Link} href="/pricing">Upgrade</Button></Box>
                    )}
                    {
                        isReceiving ? (<Box sx={{ mt: 2 }}><Chip label="Receiving Emails" /><Button onClick={handleToggleReceiving} sx={{ textTransform: 'none' }} >Stop Receiving Emails</Button></Box>
                        ) : (
                            <Box sx={{ mt: 2 }}><Chip label="Email Rejected" /><Button onClick={handleToggleReceiving} sx={{ textTransform: 'none' }} >Start Receiving Emails</Button></Box>
                        )
                    }

                </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant='h5' color={'gray'} sx={{ mb: 1 }}>
                        AI Info
                    </Typography>
                    <Typography variant='body1' sx={{ mb: 1 }}>
                        Name: {user.aiName}
                    </Typography>
                    <Typography variant='body1' sx={{ mb: 1 }}>
                        Sex: {user.sex}
                    </Typography>
                    <Typography variant='body1' sx={{ mb: 1 }}>
                        Age: {user.age}
                    </Typography>
                    <Typography variant='body1' sx={{ mb: 1 }}>
                    Introduction: {user.desc}
                    </Typography>
                </CardContent>
            </Card>
            <Button onClick={handleLogout} fullWidth variant='outlined' sx={{ mt: 2 }}>Log out</Button>
            <Dialog
                open={unsubscriptionDialogOpen}
                onClose={handleUnsubscriptionDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to cancel the subscription?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUnsubscriptionDialogClose}>取消</Button>
                    <Button onClick={handleUnsubscription} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={loading}>
                <Box p={2} display="flex" alignItems="center">
                    <CircularProgress />
                    <span style={{ marginLeft: 10 }}>...</span>
                </Box>
            </Dialog>
        </Container>

    );
}

export default Profile;
