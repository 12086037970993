import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import auth from '../configs/firebase-config';
import '../css/style.css';
import { useNavigate, useLocation } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
function MailVerified() {
    const navigate = useNavigate();
    const email = window.localStorage.getItem('emailForSignIn').toLowerCase();
    const emailLink = window.location.href;
    // const email = 'chailin181@hotmail.com'
    // const idToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImNlMzcxNzMwZWY4NmViYTI5YTUyMTJkOWI5NmYzNjc1NTA0ZjYyYmMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWFpbC1iNDJjMiIsImF1ZCI6Im1haWwtYjQyYzIiLCJhdXRoX3RpbWUiOjE3MjM0ODQ5ODQsInVzZXJfaWQiOiJ1NlNlbElnWng4UmJuQzhnSlZQbmR1Q0dhQ3gxIiwic3ViIjoidTZTZWxJZ1p4OFJibkM4Z0pWUG5kdUNHYUN4MSIsImlhdCI6MTcyMzQ4NDk4NCwiZXhwIjoxNzIzNDg4NTg0LCJlbWFpbCI6ImNoYWlsaW4xODFAaG90bWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJjaGFpbGluMTgxQGhvdG1haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.AfGj0qytqiAA8T0bA7ZpDBFyWl9vPVkaDTT0_o-EYAy41r6Yf9kqRokwcWbNtoTrs0z0GPT_7Ze4nmtzMgRh5hwhLjAv1UA-xgVN5bn3srEz53dyCm9B6GljazBqfuUY8tFM-6R2GcBtoWOxoY6bfK3uBUjTH2-gTWCj68Mvp_H-c2sfdoVtdjtx_YCaXVt-J0UsyEiel0lzHhQG5qmePAZhhtp-blRmN9Vls2B9FaZJdk_maQwOTEL116LWrpas2M99kIpvWcxU71DqXTF4ftc4yCqKgbimBs4qyXHrl2J_bwcbuWX7nUBRGyVzBg5Do2NUSMt-dckYL9ChU3n3tg'
    // fetch(apiUrl + '/verifyIdToken', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${idToken}`
    //     },
    //     body: JSON.stringify({ /* any other data */ })
    // }).then(response => {
    //     return response.json();
    // }).then(data => {
    //     console.log(data.token)
    //     localStorage.setItem('token', data.token)
    //     localStorage.setItem('email', data.email)

    // })
    //     .catch(error => {
    //         // 处理错误
    //     });
    console.log(email)
    console.log(emailLink)
    signInWithEmailLink(auth,email,emailLink)
        .then((result) => {
            // 登录成功，处理结果
            // window.localStorage.removeItem('emailForSignIn');
            auth.currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                fetch(apiUrl + '/verifyIdToken', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`
                    },
                    body: JSON.stringify({ /* any other data */ })
                }).then(response => response.json()).then(data => {
                    console.log(data)
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('email', data.email.toLowerCase())
                    navigate('/profile')
                    window.location.reload();
                })
                    .catch(error => {
                        // 处理错误
                    });

            }).catch(function (error) {
                // 处理错误
            });
        })
        .catch((error) => {
            // 处理错误
            console.error(error);
        });
    // if (isSignInWithEmailLink(auth, url)) {
    //     const email = window.localStorage.getItem('emailForSignIn');

    // }
    return (
        <Grid container className='center'>
            <Grid item xs={12} md={6} lg={4}>
                <Card sx={{ width: '100%', position: 'relative', boxShadow: 3, }}>
                    <CardContent>
                        <Typography variant="h5">
                            logging...
                        </Typography>
                    </CardContent></Card>
            </Grid>
        </Grid>
    );
}

export default MailVerified;