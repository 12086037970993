import React from 'react';
import { Button } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import auth from '../configs/firebase-config'; // 确保这个路径指向你的Firebase配置文件

function GoogleSignIn() {
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // 此处可以获取到用户信息和token
      const user = result.user;
      console.log('Logged in as:', user);
      // 可以在这里处理登录后的用户体验，如页面跳转等
    } catch (error) {
      console.error('Error during Google SignIn:', error.message);
      // 错误处理，展示给用户看
    }
  };

  return (
    <Button fullWidth onClick={handleGoogleSignIn} variant="contained" color="primary" style={{ marginTop: '20px' }}>
      Login in with Google
    </Button>
  );
}

export default GoogleSignIn;
