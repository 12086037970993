import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Grid, Box, TextField } from '@mui/material';
import { Stepper, Step, StepLabel } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/style.css'
const apiUrl = process.env.REACT_APP_API_URL;
const personalityTraits = [
  ['Solve it immediately', 'Take time to think it through and plan'],
  ['Initiate conversations', 'Quietly observe and listen'],
  ['Excited and expectant', 'Anxious and worried'],
  ['Logic and facts', 'Intuition and feelings'],
  ['Try new things and take risks', 'Maintain the status quo and stability'],
  ['Male', 'Female'],
  ['Young Adult', 'Middle-aged', 'elderly'],
]
const steps = [0, 1, 2, 3, 4, 5, 6, 7]
const titles = ['When you encounter a complex problem, you usually choose to:', 'In social situations, you prefer to:', 'When facing an uncertain future, you feel:', 'When making decisions, you tend to rely on:', 'How do you usually approach new opportunities or challenges?', 'What do you want the gender of AI to be?', 'What is your age group', 'How would you like AI to address you']


function CreateUser() {
  let [step, setStep] = useState(0);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState('');
  const navigate = useNavigate();
  const validateName = (name) => {
    const value = name.trim();

    if (value === '') {
      setErrorName('Name cannot be empty.');
      return false
    } else if (value.length > 20) {
      setErrorName('Name cannot be more than 20 characters.');
      return false
    } else if (value.includes(' ')) {
      setErrorName('Name cannot contain spaces.');
      return false
    } else {
      setErrorName('')
      return true
    }
  }
  const handleTraitSelect = (index) => {
    const newTraits = [...selectedTraits, index];
    setSelectedTraits(newTraits);
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
    else {
      
    }
  };
  const handleNext = (event) => {
    event.preventDefault(); 
    const nameRight = validateName(name)
    if (!nameRight) {
      return
    }
    navigate('/signUp', { state: { keywords: selectedTraits,name:name } });

  };
  return (
    <>
      <Grid container className='center'>
        <Grid item xs={10} md={6} lg={3}>
          <Box>
            <Stepper activeStep={step} alternativeLabel sx={{ mb: 3 }}>
              {steps.map((index) => (
                <Step key={index}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ height: '50vh', display: 'flex', borderRadius: 4, flexDirection: 'column', justifyContent: 'center', boxShadow: 3, width: '100%' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                <Box sx={{ flex: 0.9, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', }}>
                  <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, mt: 5 }} gutterBottom>
                    {titles[step]}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%',mt:6}}>
                    {step < steps.length - 1 ?
                      <>
                        {personalityTraits[step].map((trait, index) => (
                          <Button
                            variant="outlined"
                            key={trait}
                            onClick={() => handleTraitSelect(index)}
                            sx={{ marginTop: 3, borderRadius: 20, textTransform: 'none', minWidth: 300 }}
                          >
                            {trait}
                          </Button>
                        ))}
                      </> :
                      <Box component="form" onSubmit={handleNext} noValidate>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          margin="normal"

                          helperText={errorName}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth
                          type='submit'
                          style={{ marginTop: '10px' }}
                        >
                          go to sign up
                        </Button>
                      </Box>
                    }

                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateUser;
