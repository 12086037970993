import React, { useState } from 'react';
import { Card, CardContent, Grid, Box, AppBar, Toolbar, Typography, Link, Button, Container } from '@mui/material';
import { Link as Linkto } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import PayPalButton from './PayPalButton.js';
function Home() {
    const gradientBackground = {
        background: 'linear-gradient(to bottom, #ADD8E6, #FFFFFF)', // 从浅蓝到白的渐变
        height: '60vh', // 确保背景覆盖整个视口高度
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const navigate = useNavigate();
    const handleCreate = () => {
        navigate('/createUser');
    };
    const handleLogin = () => {
        navigate('/login');
    };
    return (
        <>    
            <Box style={gradientBackground}>
                <Container>
                <Grid container spacing={3}>
                        <Grid item  lg={6}>
                            <Box sx={{ width: '100%' }}>
                                <Typography variant='h4'>
                                Create an AI that aligns with your personality, receiving a personalized email from him/her every day.
                                </Typography>
                                <Typography sx={{ mt: 2 }} variant='body1'>
                                Creating an AI and receiving emails are both free of charge; you can use the application entirely for free.
                                </Typography>
                                <Button size='large' onClick={handleCreate} sx={{ textTransform: 'none',mt: 3  }} color="warning" variant="contained">Create For Free</Button>
                            </Box>
                        </Grid>
                        <Grid item  lg={6}>
                            <Box sx={{ width: '100%' }}>
                                <img style={{ width: '100%',borderRadius:'8px' }} src='images/mail-image.png' />
                            </Box>
                        </Grid>
                </Grid>
                </Container>

        </Box >

        </>
    );
}
export default Home;
