import React, { useEffect } from 'react';
const apiUrl = process.env.REACT_APP_API_URL;
const PayPalButton = () => {
  useEffect(() => {
    // 加载PayPal脚本
    const email = localStorage.getItem('email').toLowerCase();
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=ASODkBQ73mj7u-UJy-7rgJf1v6n0tChgSnqRgithwLXT802R6aZvTmJYzIDXZP4X7US21ng1dRSdk9qi&vault=true&intent=subscription';
    script.addEventListener('load', () => {
      if (window.paypal) {
        window.paypal.Buttons({
          createSubscription: function(data, actions) {
            return actions.subscription.create({
              'plan_id': 'P-0KF85772R4956943SM2NYXLA',
              'custom_id':email
            });
          },
          onApprove: function(data, actions) {
            // 处理订阅成功
            // const url = apiUrl + '/createSubscription'
            // fetch(url, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   body: JSON.stringify({email:email,sub:data.subscriptionID})
            // }).then(response => response.json())
            //   .then(da => {

            //   })
            //   .catch(error => {
            //     console.error('Error saving user details:', error)
            //   });
            // alert('Subscription completed! Subscription ID: ' + data.subscriptionID);
          }
        }).render('#paypal-button-container');
      }
    });
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalButton;
